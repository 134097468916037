import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from '../../auth/authRoles'
import StaffRoleContext from './StaffRoleContext'

const StaffList = Loadable(lazy(() => import('./StaffList')))
const StaffRole = Loadable(lazy(() => import('./StaffRole')))
const DailyTimecard = Loadable(lazy(() => import('./DailyTimecard')))
const MonthlyTimecard = Loadable(lazy(() => import('./MonthlyTimecard')))
const StaffTimeCardList = Loadable(lazy(() => import('./StaffTimeCardList')))

const staffRoutes = [
    {
        path: '/staff/daily_timecard',
        element: <DailyTimecard />,
    },
    {
        path: '/staff/monthly_timecard',
        element: <MonthlyTimecard />,
    },
    {
        path: '/staff/list',
        element: <StaffList />,
    },
    {
        path: '/staff/role',
        element: (
            <StaffRoleContext>
                <StaffRole />
            </StaffRoleContext>
        ),
    },
    {
        path: '/staff/timecard',
        element: <StaffTimeCardList />,
    },
]

export default staffRoutes
