import moment from 'moment'

export const topBarHeight = 64
export const sideNavWidth = 260
export const navbarHeight = 60
export const sidenavCompactWidth = 80
export const containedLayoutWidth = 1200
export const PORTFOLIO_IMAGE_COUNT = 13

//  meta cloud URLS
export const metaCloudApi = `https://graph.facebook.com/v19.0/:whatsappBusinessAccID/message_templates`
export const metaCloudEditTemplateURL = 'https://graph.facebook.com/v19.0/'
export const metaCloudEndpoint = 'https://graph.facebook.com/v19.0/'

// const endpoint_url = 'https://api.labeaupos.com/' //production
const endpoint_url = 'https://release-api.labeaupos.com/' //release
// const endpoint_url = 'http://localhost:8000/' //localhost

// export const websocket_url = 'wss://api.labeaupos.com' //production
export const websocket_url = 'wss://release-api.labeaupos.com' //release
// export const websocket_url = 'ws://127.0.0.1:8000'

export const api_url = endpoint_url + 'api/'
export const api_url_unauth = endpoint_url

export const firebaseConfig = {
    apiKey: 'AIzaSyBSky5cRxpUgpwoRbaZzkGypNEuoDpIHXw',
    authDomain: 'labeaupos.firebaseapp.com',
    projectId: 'labeaupos',
    storageBucket: 'labeaupos.appspot.com',
    messagingSenderId: '1053158952724',
    appId: '1:1053158952724:web:de7ebe7efb097140dfbc01',
    measurementId: 'G-NN6SPPJ9JN',
}

export const VAPIDKEY =
    'BL1edw10aIIgT0cg98koSZqQ7Ifg4CNPdWBQFkRjil2qKqiAd-J_dJ8WhhiXmDbUJZJE8OuOrmYm14jzIh01fJc'

const shopInfo = JSON.parse(localStorage.getItem('shopInfo'))
const activeUser = JSON.parse(localStorage.getItem('activeUser'))

export const DIALOG_TEMPLATE = {
    title: '',
    content: '',
    isWarning: false,
    isInfo: false,
    isSuccess: false,
}

export const INITIAL_STAFF = {
    id: '',
    user: {
        id: '',
        username: '',
        password: '',
        confirm_password: '',
        access_code: '',
    },
    first_name: '',
    last_name: '',
    phone_code: '',
    contact: '',
    address_1: '',
    address_2: '',
    dob: null,
    email: '',
    gender: '',
    role: '',
    access_code: '',
    position: '',
    service_price: '',
    effective_date: new Date(),
    portfolio_images: [],
    deactivate_date: null,
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
    image_url: '',
    reviewCount: '',
    remark: '',
}

export const INITIAL_STAFF_FORM_ERROR = {
    id: '',
    user: {
        id: '',
        username: '',
        password: '',
        confirm_password: '',
        access_code: '',
    },
    first_name: '',
    last_name: '',
    phone_code: '',
    contact: '',
    address_1: '',
    address_2: '',
    dob: null,
    email: '',
    gender: '',
    role: '',
    access_code: '',
    position: '',
    service_price: '',
    effective_date: new Date(),
    portfolio_images: [],
    deactivate_date: null,
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
    image_url: '',
    reviewCount: '',
    remark: '',
}

export const INITIAL_MEMBERSHIP = {
    id: '',
    grade: '',
    target: '',
    spending_amount: '',
    point_value: '',
    grade_error: false,
    target_error: false,
    spending_amount_error: false,
    point_value_error: false,
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_REFERRAL = {
    id: '',
    tier: '',
    type: '',
    reward: 0,
    reward_error: false,
    type_error: false,
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_POSITION_FORM_ERROR = {
    id: '',
    name: '',
    selectedItem: '',
}

export const INITIAL_ORDER = {
    id: '',
    member_profile: {},
    customer_profile: {},
    date: new Date().toString(), // For the document date
    doc_date: moment(new Date()).format('YYYYMMDD').toString(), // For the document number purpose
    marketing_channel: {},
    po_no: '',
    inv_no: '',
    qn_no: '',
    do_no: '',
    item_list: [],
    payment: [],
    subtotal: '',
    total: '',
    credit_applied: '',
    point_applied: '',
    discount_amount: '',
    service_charge: '',
    discount: {},
    rounding: 0,
    balance: '',
    tax: '',
    payment_remark: shopInfo?.payment_remark || '',
    remark: shopInfo?.doc_remark || '',
    image_list: [],
    type: 'Invoice',
    source: 'SHOP',
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
    created_date: new Date().toString(),
}

export const INITIAL_QUOTATION = {
    id: '',
    member_profile: {},
    customer_profile: {},
    date: moment(new Date()).format('YYYY-MM-DD'), // For the document date
    doc_date: moment(new Date()).format('YYYY-MM-DD'), // For the document number purpose
    po_no: '',
    inv_no: '',
    qn_no: '',
    do_no: '',
    item_list: [],
    payment: [],
    subtotal: '',
    rounding: 0,
    total: '',
    discount_amount: '',
    discount: {},
    balance: '',
    tax: '',
    remark: shopInfo?.doc_remark || '',
    image_list: [],
    type: 'Quotation',
    source: 'SHOP',
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_ORDERLIST_FORM_ERROR = {
    credit_applied: '',
    point_applied: '',
}

export const INITIAL_REMARK = {
    id: '',
    title: '',
    content: '',
    member_profile: '',
    date: new Date(),
    updated_by: activeUser?.id || null,
}

export const INITIAL_MEMBER = {
    id: '',
    first_name: '',
    last_name: '',
    dob: '',
    gender: '',
    phone_code: '',
    contact: '',
    email: '',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    postcode: '',
    country: null,
    nric_passport: '',
    emergency_first_name: '',
    emergency_last_name: '',
    emergency_phone_code: '',
    emergency_contact: '',
    member_gallery: [],
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser?.id || null,
}

export const INITIAL_MEMBER_FORM_ERROR = {
    first_name: '',
    last_name: '',
    phone_code: '',
    contact: '',
    date: '',
    email: '',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    postcode: '',
    country: null,
    nric_passport: '',
    emergency_first_name: '',
    emergency_last_name: '',
    emergency_phone_code: '',
    emergency_contact: '',
}

export const INITIAL_MERCHANT = {
    id: '',
    first_name: '',
    last_name: '',
    phone_code: '',
    contact: '',
    address_1: '',
    address_2: '',
    effective_date: new Date(),
    deactivate_date: '',
    email: '',
    remark: '',
    country: '',
    updated_by: activeUser || null,
    user: {
        id: '',
        username: '',
        last_name: '',
        first_name: '',
        role: '',
        email: '',
        password: '',
        confirm_password: '',
        access_code: '',
    },
    merchant: {
        id: '',
        company_name: '',
        business_registration_no: '',
        phone_code: '',
        office_contact: '',
        tax_no: '',
        address_1: '',
        address_2: '',
        effective_date: null,
        deactivate_date: null,
        remark: '',
    },
}

export const INITIAL_MERCHANT_FORM_ERROR = {
    id: '',
    first_name: '',
    last_name: '',
    phone_code: '',
    contact: '',
    address_1: '',
    address_2: '',
    effective_date: new Date(),
    deactivate_date: '',
    email: '',
    remark: '',
    country: '',
    updated_by: activeUser || null,
    user: {
        id: '',
        username: '',
        last_name: '',
        first_name: '',
        role: '',
        email: '',
        password: '',
        confirm_password: '',
        access_code: '',
    },
    merchant: {
        id: '',
        company_name: '',
        business_registration_no: '',
        phone_code: '',
        office_contact: '',
        tax_no: '',
        address_1: '',
        address_2: '',
        effective_date: null,
        deactivate_date: null,
        remark: '',
    },
}

export const INITIAL_CUSTOMER_COMPANY = {
    id: '',
    code: '',
    company_name: '',
    company_address_1: '',
    company_address_2: '',
    company_phone_code: '',
    company_contact: '',
    company_email: '',
    payment_term: '',
    outstanding: 0,
    last_visit: '',
    last_spending_amount: 0,
    delivery_address_1: '',
    delivery_address_2: '',
    delivery_attention: '',
    delivery_phone_code: '',
    delivery_contact: '',
    remark: '',
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_CUSTOMER_COMPANY_FORM_ERROR = {
    id: '',
    code: '',
    company_name: '',
    company_address_1: '',
    company_address_2: '',
    company_phone_code: '',
    company_contact: '',
    company_email: '',
    payment_term: '',
    outstanding: '',
    last_visit: '',
    last_spending_amount: '',
    delivery_address_1: '',
    delivery_address_2: '',
    delivery_attention: '',
    delivery_phone_code: '',
    delivery_contact: '',
    remark: '',
}

export const INITIAL_SHOP = {
    code: '',
    merchant: {
        company_name: '',
        business_registration_no: '',
        phone_code: '',
        office_contact: '',
        tax_no: '',
    },
    name: '',
    logo_url: '',
    opening_time: '',
    closing_time: '',
    address_1: '',
    address_2: '',
    phone_code: '',
    contact: '',
    whatsapp_phone_code: '',
    whatsapp_no: '',
    waba_phone_code: '',
    waba_no: '',
    waba_api_key: '',
    website: '',
    email: '',
    business_type: '',
    qrcode: '',
    manager: '',
    remark: '',
    doc_remark: '',
    payment_remark: '',
    signature: '',
    location: '',
    shop_config: {
        name: '',
        service_charge: '',
        taxable: '',
        tax_inclusive: '',
        spending_amount: 0,
        point_value: 0,
        address_1: '',
        address_2: '',
        phone_code: '',
        contact: '',
        whatsapp_phone_code: '',
        whatsapp_no: '',
        website: '',
        email: '',
        doc_remark: '',
        payment_remark: '',
        location: '',
    },
    updated_by: activeUser || null,
}

export const INITIAL_SHOP_FORM_ERROR = {
    code: '',
    merchant: '',
    name: '',
    logo_url: '',
    opening_time: '',
    closing_time: '',
    address_1: '',
    address_2: '',
    phone_code: '',
    contact: '',
    whatsapp_no: '',
    waba_no: '',
    waba_api_key: '',
    website: '',
    email: '',
    business_type: '',
    qrcode: '',
    manager: '',
    remark: '',
    shop_config: {
        service_charge: '',
        taxable: '',
        tax_inclusive: '',
        spending_amount: '',
        point_value: '',
    },
    updated_by: activeUser || null,
}

export const INITIAL_CATEGORY = {
    id: '',
    name: '',
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_CATEGORY_FORM_ERROR = {
    id: '',
    name: '',
    selectedItem: '',
}

export const INITIAL_PRODUCT = {
    id: '',
    price: '',
    name: '',
    product_images: [],
    stock_quantity: '',
    category: '',
    description: '',
    reward_point: '',
    product_commission: [],
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_PRODUCT_FORM_ERROR = {
    id: '',
    price: '',
    name: '',
    product_images: [],
    stock_quantity: '',
    category: '',
    description: '',
    reward_point: '',
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_STOCK_TRANSACTION = {
    id: '',
    doc_no: '',
    doc_date: '',
    product: '',
    quantity: '',
    price: '',
    remark: '',
    transaction_date: '',
    action: '',
    transaction_images: [],
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_STOCK_FORM_ERROR = {
    id: '',
    doc_no: '',
    product: '',
    quantity: '',
    price: '',
    remark: '',
    transaction_date: '',
    transaction_type: '',
    image_list: [],
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_SERVICE = {
    id: '',
    price: '',
    name: '',
    image_url: '',
    category: '',
    duration_minutes: '',
    next_service_day: '',
    description: '',
    reward_point: '',
    service_commission: [],
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_SERVICE_FORM_ERROR = {
    name: '',
    price: '',
    duration_minutes: '',
    next_service_day: '',
    category: '',
    description: '',
    reward_point: '',
}

export const INITIAL_EXPENSE = {
    id: '',
    date: new Date(),
    title: '',
    remark: '',
    item: [],
    receipt: [],
    subtotal: 0,
    tax_amount: 0,
    service_charge: 0,
    total: 0,
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_EXPENSE_FORM_ERROR = {
    id: '',
    title: '',
    remark: '',
    item: {
        name: '',
        unit_price: '',
        quantity: '',
        price: '',
    },
    receipt: [],
    subtotal: '',
    tax_amount: '',
    service_charge: '',
    total: '',
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_PACKAGE = {
    id: '',
    price: '',
    name: '',
    image_url: '',
    description: '',
    reward_point: '',
    expiry_type: '',
    expiry_duration: '',
    package_service: [],
    package_product: [],
    package_credit: [],
    package_commission: [],
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_PACKAGE_FORM_ERROR = {
    id: '',
    price: '',
    name: '',
    image_url: '',
    description: '',
    reward_point: '',
    expiry_type: '',
    expiry_duration: '',
    package_service: [],
    package_product: [],
    package_credit: [],
    item_row: '',
    package_commission: [],
}

export const INITIAL_CREDIT = {
    id: '',
    name: '',
    price: '',
    credit_value: '',
    image_url: '',
    reward_point: '',
    application_type: 'ALL',
    expiry_duration: 0,
    expiry_type: '',
    credit_commission: [],
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_CREDIT_FORM_ERROR = {
    name: '',
    price: '',
    credit_value: '',
    reward_point: '',
}

export const INITIAL_BILL = {
    id: '',
    shop: (shopInfo && shopInfo.id) || '',
    member_profile: {},
    order: [],
    staff_incharge: '',
    price: '',
    created_date: '',
    created_by: activeUser || null,
}

export const INITIAL_APPOINTMENT = {
    id: '',
    member: '',
    date: new Date().toJSON().slice(0, 10),
    start: new Date(),
    end: new Date(),
    service: '',
    no_of_pax: '',
    room: '',
    staff: '',
    package: '',
    marketing_channel: '',
    recorded_by: '',
    remark: '',
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_APPOINTMENT_FORM_ERROR = {
    id: '',
    member: '',
    date: new Date().toJSON().slice(0, 10),
    start: '',
    end: '',
    no_of_pax: '',
    service: '',
    room: '',
    staff: '',
    package: '',
    marketing_channel: '',
    recorded_by: '',
    remark: '',
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_CHANNEL = {
    id: '',
    name: '',
    modified: '',
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_CHANNEL_FORM_ERROR = {
    id: '',
    name: '',
    modified: '',
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_COUPON = {
    id: '',
    name: '',
    amount: '',
    modified: '',
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_DISCOUNT = {
    id: '',
    name: '',
    amount: '',
    type: 1,
    modified: '',
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_DISCOUNT_METHOD_FORM_ERROR = {
    id: '',
    name: '',
    amount: '',
    type: 1,
    modified: '',
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_PAYMENT_TERM = {
    id: '',
    term: '',
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_PAYMENT_TERM_FORM_ERROR = {
    id: '',
    term: '',
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_ROOM = {
    id: '',
    name: '',
    modified: '',
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_PAYMENT_METHOD = {
    id: '',
    name: '',
    is_cash: false,
    shop: (shopInfo && shopInfo.id) || '',
    modified: '',
    updated_by: activeUser || null,
}

export const INITIAL_PAYMENT_METHOD_FORM_ERROR = {
    id: '',
    name: '',
    is_cash: false,
    shop: (shopInfo && shopInfo.id) || '',
    modified: '',
    updated_by: activeUser || null,
}

export const INITIAL_COUNTRY = {
    name: '',
    code: '',
    currency: '',
    tax: '',
}

export const INITIAL_REVIEW = {
    id: '',
    member_profile: {},
    staff: { id: '', username: '' },
    comment: '',
    rating: '',
}

export const INITIAL_COMMISSION = {
    id: '',
    role: '',
    category: '',
    target: [
        {
            id: '',
            start_range: 0,
            end_range: 0,
            start_error: false,
            end_error: false,
            commission_amount: 0,
            commission_type: 1,
        },
    ],
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_APPOINTMENT_REMINDER = {
    id: '',
    name: '',
    modified: '',
    shop: (shopInfo && shopInfo.id) || '',
    updated_by: activeUser || null,
}

export const INITIAL_REMINDER_FORM_ERROR = {
    time_unit: '',
    waba_template: '',
    time_count: '',
    language: '',
}

// parent menu name
export const PARENT_NAVIGATIONS_LIST = [
    'appointment',
    'billing',
    'commission',
    'credit',
    'customer_company',
    'dashboard',
    'expense',
    'inventory',
    'invoice',
    'master_data',
    'member',
    'merchant',
    'package',
    'quotation',
    'report',
    'service',
    'shop_settings',
    'staff',
    'waba',
    // 'waba_credit',
    'webstore',
]

// sub menu name
// to wirte a sub menu name parent_sbumenuname
export const ALL_NAVIGATION_PERMISSION_LSIT = [
    'appointment_appointment',
    'appointment_reminder',
    'appointment_archived_list',
    'billing_billing_history',
    'billing_billing',
    'commission',
    'credit',
    'customer_company_customer_company',
    'customer_company_payment_term',
    'dashboard',
    'expense_expense_history',
    'expense_expense_list',
    'inventory_product_list',
    'inventory_stock_history',
    'invoice_invoice_history',
    'invoice_invoice',
    'master_data_country',
    'master_data_currency',
    'master_data_phone_code',
    'master_data_tax',
    'member_member',
    'member_member_birthday',
    'member_membership',
    'member_referral',
    'merchant_business_type',
    'merchant_merchant_list',
    'merchant_shop_list',
    'package',
    'quotation_quotation_history',
    'quotation_quotation',
    'report_credit_report',
    'report_daily_report',
    'report_discount_report',
    'report_hourly_report',
    'report_monthly_report',
    'report_stock_history_report',
    'report_outstanding_report',
    'report_void_report',
    'report_waba_credit_report',
    'report_weekly_report',
    'report_yearly_report',
    'report_sale_commission_report',
    "report_appointment_report",
    'report_member_package_balance_report',
    'service',
    'shop_settings_coupon',
    'shop_settings_discount',
    'shop_settings_marketing_channel',
    'shop_settings_payment_method',
    'shop_settings_room',
    'shop_settings_system_security_lock',
    // 'staff_daily_timecard',
    // 'staff_monthly_timecard',
    'staff_staff_list',
    'staff_staff_role',
    'waba_chatroom',
    'waba_credit_reload',
    'waba_credit_history',
    'waba_whatsapp_marketing',
    'waba_whatsapp_template',
    // 'webstore_checkout',
    // 'webstore_order_list',
    // 'webstore_shopping_cart',
    // 'webstore_webstore',
]
