export const SET_PERMISSIONS_INFO = 'SET_PERMISSIONS_INFO';

const initialState = {
    permissions_info : {},
}

const PermissionsInfo = function (state = initialState, action) {
    switch (action.type) {
        case SET_PERMISSIONS_INFO: {
            return {
                ...state,
                permissions_info: action.payload,
            }
        }
        default: {
            return state
        }
    }
}

export default PermissionsInfo